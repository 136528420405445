import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  authedNavLinks,
  authedNavLinkSubMenus,
  generalNavLinks,
  generalNavLinksWithSubMenu,
} from "../../Raw/menuLinks";
import { showMenuAndMoveMainAnimation } from "../../Tools/animations";
import {
  IonChevronBack,
  IonChevronForward,
  IonLogoWhatsapp,
  IonMdLogOut,
  IonCard,
} from "../icons";
import { AuthLinks } from "./otherForNav";
import { useAuth } from "../../contexts/auth";

export const GeneralSideBar = ({ props }) => {
  const location = useLocation();
  const [menuLink, setML] = useState([]);
  const [menuWithSubMenuLink, setMWSML] = useState([]);
  const [subMenuLevel1Links, setSML1L] = useState([]);

  useEffect(() => {
    setML(generalNavLinks);
    setMWSML(generalNavLinksWithSubMenu);
  }, []);

  useEffect(() => {
    let notAuthMain = document.querySelector("main");
    if (notAuthMain && notAuthMain.classList.contains("mainMoveRight")) {
      showMenuAndMoveMainAnimation();
    }
  }, [location]);

  return (
    <aside className="mobileGeneralSidebar">
      <section className="mgs-closer">
        <p onClick={showMenuAndMoveMainAnimation} className="flex">
          <IonChevronBack /> Retour
        </p>
      </section>
      <div className="sidebarLinksList">
        {menuLink.map((ct, index) => (
          <Link to={ct.link} key={"sidebar g nav nb" + index}>
            <span>{ct.label} </span>
          </Link>
        ))}
        {menuWithSubMenuLink.map((ct, index) => {
          return (
            <p
              key={"sidebar g nav with sub menu nb" + index}
              className="withIcon"
              onClick={() => setSML1L(ct.subLinks)}
            >
              <span>{ct.label} </span>
              <IonChevronForward />
            </p>
          );
        })}
      </div>

      <SidebarShowSubMenuLevel1
        customProps={{ sml1inks: subMenuLevel1Links, setSML1L }}
      />
      <AuthLinks />
    </aside>
  );
};

export const AuthedSideBar = (props) => {
  const { logout } = useAuth();
  const location = useLocation();
  const { authSidebar, authedInfos, setAuthSidebar, authedMentor } = props;
  const agentPhoneNumber = authedMentor.telephone;

  useEffect(() => {
    setAuthSidebar(false);
  }, [location]);

  return (
    <aside className={"an-sidebar " + (authSidebar ? "ans-show" : "ans-hide")}>
      <section className="ans-header" onClick={() => setAuthSidebar(false)}>
        <IonChevronBack />
      </section>

      <section className="ans-body">
        {authedNavLinks.map((link, index) => (
          <div key={"auth sidebar link" + index}>
            {
              <Link to={link.link} className="withIcon">
                {link.icon}
                {link.label}
              </Link>
            }
          </div>
        ))}
        <a
          href={
            "https://api.whatsapp.com/send?phone=33" + agentPhoneNumber.slice(1)
          }
          target={"_blank"}
          className="withIcon"
        >
          <IonLogoWhatsapp />
          Discussion WhatSapp
        </a>

        <a
          href="https://billing.stripe.com/p/login/6oE3ggeoV37w1wsbII"
          target={"_blank"}
          className="withIcon"
        >
          <IonCard />
          Mettre à jour ma carte
        </a>

        {authedNavLinkSubMenus.map((link, index) => (
          <div key={"auth sidebar link" + index}>
            {
              <Link to={link.link} className="withIcon">
                {link.icon}
                {link.label}
              </Link>
            }
          </div>
        ))}
      </section>
      <section className="ans-footer">
        <span
          className="withIcon"
          onClick={() => {
            logout();
          }}
        >
          <IonMdLogOut />
          Se déconnecter
        </span>
      </section>
    </aside>
  );
};
function SidebarShowSubMenuLevel1({ customProps }) {
  const { sml1inks, setSML1L } = customProps;
  return (
    <div
      className={
        "sidebarShowSubMenuLevel1 " +
        (sml1inks.length > 0 ? "subLinkToLeft" : "")
      }
    >
      <section>
        <b onClick={() => setSML1L([])}>Retour</b>
      </section>
      <div className="sidebarLinksList">
        {sml1inks.map((sl, ind) => (
          <Link to={sl.link} key={"sidebar sub menu nb" + ind}>
            <span>{sl.label} </span>
          </Link>
        ))}
      </div>
    </div>
  );
}
