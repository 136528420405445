export function FormButton(props) {
  const { type, text, autoWidth, isValid } = props;

  function divStyle() {
    if (autoWidth) {
      return {};
    }
    return { width: "80%", margin: "auto" };
  }
  return (
    <div style={divStyle()} className="formBtn">
      <button
        type="submit"
        className={isValid ? "" : "btnDisabled"}
        style={{
          borderRadius: "8px",
          width: autoWidth ? "auto" : "100%",
        }}
        disabled={isValid ? false : true}
      >
        {text != "" ? text : "...."}
      </button>
    </div>
  );
}
